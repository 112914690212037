export const wrapInPromise = <T>(val: T): Promise<T> => new Promise<T>((resolve) => { resolve(val); });

// See https://stackoverflow.com/a/43001581
export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

// See https://stackoverflow.com/a/61108377
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

type Nil = null | undefined;
export type Nullable<T> = T | Nil;

// see https://medium.com/mizyind-singularity/remove-blank-attributes-from-an-object-in-typescript-with-type-safe-ad4fd78a061c
type WithoutNils<T> = { [K in keyof T as T[K] extends Nil ? never : K]: T[K] };

// On a later version of TS, this will become:
// export const removeNils = <T extends { [k: string]: unknown }, V = WithoutNils<T>>(obj: T): V => {
export const removeNils = <T, V = WithoutNils<T>>(obj: T): V => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v !== undefined && v !== null),
  ) as V;
};

// See https://bobbyhadz.com/blog/typescript-make-single-property-optional
export type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;
