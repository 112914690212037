import React from 'react';
import Head from 'next/head';
import * as Sentry from '@sentry/nextjs';

interface Props {
  pixelId: string;
}

const FacebookPixel: React.FC<Props> = ({ pixelId }) => {
  // Script for initializing Facebook Pixel
  const facebookPixelScript = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('set', 'autoConfig', false, '${pixelId}');
    fbq('init', '${pixelId}');
    fbq('track', 'PageView');
  `;

  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: facebookPixelScript }} />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
        />
      </noscript>
    </Head>
  );
};

// Function to track events with event_id
const trackFacebookEvent = (eventName: string, eventId: string, parameters?: Record<string, any>) => {
  if (typeof window !== 'undefined' && window.fbq) {
    try {
      window.fbq('track', eventName, { ...parameters, event_id: eventId });
      return true;
    } catch (e) {
      Sentry.captureException(e, {
        contexts: {
          fb_pixel: {
            eventName,
            eventId,
            parameters,
          }
        },
      });
      return false;
    }
  }
};

export default FacebookPixel;
export { trackFacebookEvent };
