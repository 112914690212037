import * as HeroIconsOutline from '@heroicons/react/outline';
import * as HeroIconsSolid from '@heroicons/react/solid';
import React, { FunctionComponent } from 'react';
import grid from 'styles/grid';
import pallette from 'styles/pallette';

export type MentalIconType = HeroIconType;
export type MentalIconName = HeroIconName;
export type MentalIconProps = {
  iconName: MentalIconName,
  iconType?: MentalIconType,
  dimension?: number, // use this for square icons
  nonSquareDimensions?: {
    width: number,
    height: number,
  },
  color?: string,
  altColor?: string,
  disabledColor?: string,
  disabled?: boolean,
  style?: React.CSSProperties,
};

const MentalIcon = ({
  iconName,
  iconType,
  color = pallette.primary.w500,
  altColor,
  disabledColor = pallette.neutral.w300,
  dimension = grid.Large,
  nonSquareDimensions,
  disabled,
  style,
}: MentalIconProps) => {
  const width = nonSquareDimensions?.width ?? dimension;
  const height = nonSquareDimensions?.height ?? dimension;

  return (
    <HeroIcon
      iconName={iconName as HeroIconName}
      iconType={iconType}
      width={width}
      height={height}
      color={disabled ? disabledColor : color}
      style={style}
    />
  );
};

export default MentalIcon;

const heroIconTypes = [
  'outline',
  'solid',
] as const;
export type HeroIconType = typeof heroIconTypes[number];

type HeroIconName = keyof typeof HeroIconsOutline;

interface HeroIconProps extends Omit<MentalIconProps, 'iconName'> {
  iconName: HeroIconName,
  width: number,
  height: number,
}

export const iconNameIsValid = (iconName: string | undefined): iconName is MentalIconName => {
  if (!iconName) return false;

  return iconName in HeroIconsOutline;
};

const HeroIcon: FunctionComponent<HeroIconProps> = ({
  iconName,
  iconType,
  color,
  width,
  height,
  style,
}: HeroIconProps) => {
  const TheIcon = (() => {
    switch (iconType) {
      default:
      case 'outline': return HeroIconsOutline[iconName];
      case 'solid': return HeroIconsSolid[iconName];
    }
  })();
  if (!TheIcon) return null;
  return (
    <TheIcon
      color={color}
      width={width}
      height={height}
      style={style}
    />
  );
};
