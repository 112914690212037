import React from 'react';

const sizes = {
  superheading1: 'text-[52px] md:text-[72px]',
  superheading2: 'text-[42px] md:text-[56px]',
  heading1: 'text-[28px] md:text-[40px]',
  heading2: 'text-[24px] md:text-[32px]',
  subhead1: 'text-[18px] md:text-[24px]',
  subhead2: 'text-[16px] md:text-[20px]',
  eyebrow1: 'text-[12px] md:text-[24px]',
  eyebrow2: 'text-[20px]',
  eyebrow3: 'text-[16px]',
  paragraphLarge: 'text-[18px]',
  paragraph: 'text-[16px]',
  paragraphSmall: 'text-[14px]',
  paragraphXSmall: 'text-[12px]',
};

const weights = {
  regular: 'font-normal',
  bold: 'font-bold',
  heavy: 'font-extrabold',
};

type MentalTextProps = {
  size: keyof typeof sizes,
  style?: React.CSSProperties,
  bold?: boolean,
  heavy?: boolean,
  italic?: boolean,
  centered?: boolean,
  isSpan?: boolean,
  color?: string,
  isAccentFont?: boolean,
  children: React.ReactNode,
  className?: string;
};

const MentalText = ({
  size = 'paragraph', style, bold, heavy, italic, centered, isSpan, color, children, isAccentFont, className
}: MentalTextProps) => {
  const fontSizeClass = sizes[size] || 'text-base';
  const fontWeightClass = bold ? weights.bold : heavy ? weights.heavy : weights.regular;
  const fontStyleClass = italic ? 'italic' : '';
  const textAlignClass = centered ? 'text-center' : 'text-left';
  const displayClass = isSpan ? 'inline-block' : 'block';
  const fontFamilyClass = isAccentFont ? 'font-gta' : 'font-nexa';

  return (
    <p
      className={`${fontSizeClass} ${fontWeightClass} ${fontStyleClass} ${textAlignClass} ${displayClass} ${fontFamilyClass} ${className}`}
      style={{ color, lineHeight: isAccentFont ? 'normal' : undefined, ...style }}
    >
      {children}
    </p>
  );
};


const createComponent = (size: keyof typeof sizes) => ({ children, ...props }: Omit<MentalTextProps, 'size'>) => <MentalText size={size} {...props}>{children}</MentalText>;

export default {
  Superheading1: createComponent('superheading1'),
  Superheading2: createComponent('superheading2'),
  Heading1: createComponent('heading1'),
  Heading2: createComponent('heading2'),
  Subhead1: createComponent('subhead1'),
  Subhead2: createComponent('subhead2'),
  Eyebrow1: createComponent('eyebrow1'),
  Eyebrow2: createComponent('eyebrow2'),
  Eyebrow3: createComponent('eyebrow3'),
  ParagraphLarge: createComponent('paragraphLarge'),
  Paragraph: createComponent('paragraph'),
  ParagraphSmall: createComponent('paragraphSmall'),
  ParagraphXSmall: createComponent('paragraphXSmall'),
};
