import {
  Identify, identify, init, setDeviceId, setUserId, track,
} from '@amplitude/analytics-browser';
import { AnalyticsEventName, AnalyticsProperties, ValidAnalyticsProperties } from 'libs/analytics/types';
import { isInBrowser } from 'libs/browser';
import { getDeviceIdAndSources, InitialDeviceIdValues, getInitialDeviceIdValues } from 'models/device_id';

// Logging Helpers
const LoggingEnabled = false; // never commit anything except `false`
const AnalyticsLog = LoggingEnabled ? console.log.bind(console) : () => {};

const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
let didInitAmplitude = false;

class MentalAnalytics {
  deviceId: string | undefined;
  deviceIdSources: string[] | undefined;
  initialDeviceIdValues: InitialDeviceIdValues | undefined;

  constructor() {
    if (isInBrowser && AMPLITUDE_API_KEY) {
      didInitAmplitude = true;
      init(AMPLITUDE_API_KEY);

      const deviceIdAndSources = getDeviceIdAndSources();
      this.deviceId = deviceIdAndSources.deviceId;
      this.deviceIdSources = deviceIdAndSources.sources;

      this.initialDeviceIdValues = getInitialDeviceIdValues();

      setDeviceId(this.deviceId);
      this.trackEvent('App : Landed');
      this.trackEvent('Web : Device Id : Tracked', {
        ...this.initialDeviceIdValues,
      });
    }
  }

  trackError(error: any, context: string, properties: AnalyticsProperties) {
    // TODO: Send to bugsnag or similar
  }

  getReferralSourceProps () {
		try {
      const referralUrl = document.referrer;
      let referralSource = "Other";
      if (referralUrl.includes("facebook.com")) {
        referralSource = "Facebook";
      } else if (referralUrl.includes("google.com")) {
        referralSource = "Google";
      } else if (referralUrl === "") {
        referralSource = "Direct";
      }
      return {
        referral_source: referralSource,
		    referral_url: document.referrer
      }
		} catch (err) {
			return {
        referral_source: 'Unknown',
		    referral_url: 'Unknown'
      }
		}
	}

  getUTMProps() {
    const utmProps: AnalyticsProperties = {};
    try {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.forEach((value, key) => {
        if (key.startsWith('utm_')) {
          utmProps[key] = value;
        }
      });
    } catch (err) {
      if (err instanceof Error) {
        utmProps.utm_error = err.toString();
      } else {
        utmProps.utm_error = String(err);
      }
    }
    return utmProps;
  }

  trackEvent(eventName: AnalyticsEventName, eventProperties?: AnalyticsProperties) {
    if (!didInitAmplitude) return;
    const fullProperties = {
      ...this.cleanProperties(eventProperties),
      ...this.getReferralSourceProps(),
      ...this.getUTMProps(),
      mental_device_id: this.deviceId,
      mental_device_id_sources: this.deviceIdSources?.join(','),
      website_pathname: window.location.pathname,
      website_host: window.location.host,
      website_search: window.location.search,
      website_href: window.location.href,
    };
    AnalyticsLog(`MentalAnalytics : Track Event \`${eventName}\` with properties ${JSON.stringify(fullProperties, null, 2)}`);
    return track(eventName, fullProperties);
  }

  // remove undefineds, and convert Dates to ISO8601 strings
  private cleanProperties(properties?: AnalyticsProperties): ValidAnalyticsProperties {
    const cleanedProperties: ValidAnalyticsProperties = {};
    Object.entries(properties ?? {}).forEach((entry) => {
      const [key, value] = entry;
      if (!value) { return; }
      if (value instanceof Date) {
        cleanedProperties[key] = value.toISOString();
        return;
      }
      cleanedProperties[key] = value;
    });
    return cleanedProperties;
  }

  updateUserProperties(userProperties: AnalyticsProperties) {
    if (!didInitAmplitude) return;
    AnalyticsLog('MentalAnalytics : Update User Properties', JSON.stringify(userProperties, null, 2));
    const event = new Identify();
    const cleanProperties = this.cleanProperties(userProperties);
    Object.entries(cleanProperties).forEach((entry) => {
      const [key, value] = entry;
      event.set(key, value);
    });
    identify(event);
  }

  identifyUser(userId?: string, email?: string) {
    if (!didInitAmplitude) return;
    AnalyticsLog('MentalAnalytics : Update User', { userId, email });

    this.updateUserProperties({ email });

    if (userId) {
      setUserId(userId);
    }
  }

  // Call this when user logs out
  resetUser() {
    if (!didInitAmplitude) return;
    // TODO: Is this available in the new TS SDK?
  }

  errorProperties(error: any, includeStack: boolean = false): AnalyticsProperties {
    return {
      error_code: error?.code,
      error_name: error?.name,
      error_message: error?.message,
      error_stack: includeStack ? error?.stack : undefined,
    };
  }
}

export default new MentalAnalytics();
