import 'styles/globals.css';

import type { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import FacebookPixel from 'components/analytics/FacebookPixel';
import TikTokPixel from 'components/analytics/TikTokPixel';
import withErrorBoundary from 'hooks/useErrorBoundary';
import XPixel from 'components/analytics/XPixel';
import GTag from 'components/analytics/GTag';
// import OneSignal from 'react-onesignal';

const NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID;
const NEXT_PUBLIC_FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
const NEXT_PUBLIC_TIKTOK_PIXEL_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID;
const NEXT_PUBLIC_X_PIXEL_ID = process.env.NEXT_PUBLIC_X_PIXEL_ID;
const NEXT_PUBLIC_GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
// const NEXT_PUBLIC_ONESIGNAL_APP_ID = process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID;
// const NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID = process.env.NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID;

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [isOneSignalReady, setIsOneSignalReady] = useState(false);
  useEffect(() => {
    (async () => {
      if (process.env.NODE_ENV === 'development') {
        setIsOneSignalReady(true);
        return;
      };

      // if ('serviceWorker' in navigator) {
      //   window.addEventListener('load', () => {
      //     navigator.serviceWorker.register('/OneSignalSDKWorker.js').then(registration => {
      //       console.log('ServiceWorker registration successful with scope: ', registration.scope);
      //     }).catch(error => {
      //       console.log('ServiceWorker registration failed: ', error);
      //     });
      //   });
      // }

      // console.log('INIT ONE SIGNAL, ', process.env.NODE_ENV);
      // await OneSignal.init({
      //   appId: `${NEXT_PUBLIC_ONESIGNAL_APP_ID}`,
      //   safari_web_id: `${NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID}`,
      //   allowLocalhostAsSecureOrigin: true,
      // });
      setIsOneSignalReady(true);
      // console.log('AFTER INIT ONE SIGNAL, ', NEXT_PUBLIC_ONESIGNAL_APP_ID);
    })();
  }, []);

  const WrappedComponent = withErrorBoundary(Component);

  return (
    <GoogleOAuthProvider clientId={`${NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}`}>
      {NEXT_PUBLIC_FACEBOOK_PIXEL_ID && <FacebookPixel pixelId={`${NEXT_PUBLIC_FACEBOOK_PIXEL_ID}`} />}
      {NEXT_PUBLIC_TIKTOK_PIXEL_ID && <TikTokPixel pixelId={`${NEXT_PUBLIC_TIKTOK_PIXEL_ID}`} />}
      {NEXT_PUBLIC_X_PIXEL_ID && <XPixel pixelId={`${NEXT_PUBLIC_X_PIXEL_ID}`} />}
      {NEXT_PUBLIC_GA_TRACKING_ID && <GTag />}
      {isOneSignalReady && <WrappedComponent {...pageProps} />}
    </GoogleOAuthProvider>
  );
};

export default MyApp;
