import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

const GTag: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`;
    document.head.appendChild(script);

    const scriptInline = document.createElement('script');
    scriptInline.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.NEXT_PUBLIC_GA_TRACKING_ID}');
    `;
    document.head.appendChild(scriptInline);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(scriptInline);
    };
  }, []);

  return null;
};

type GAdParameters = Partial<{
  transaction_id?: string;
  value: number;
  currency: string;
}>;

export const trackGAdsConversionEvent = (eventId: string, parameters?: GAdParameters) => {
  if (typeof window === 'undefined') return;
  // @ts-ignore
  const { gtag } = window;
  if (!gtag) return;

  try {
    gtag('event', 'conversion', {
      send_to: eventId,
      ...parameters
    });
    return true;
  } catch (error) {
    Sentry.captureException(error, {
      contexts: {
        google_ads_data: {
          ...parameters,
        }
      },
    });
    return false;
  }
}

export default GTag;
