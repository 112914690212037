import Favicon from 'components/foundation/head/Favicon';
import Fonts from 'components/foundation/head/Fonts';
import Head from 'next/head';
import React from 'react';

const DefaultHead = ({ title }: { title?: string }) => {
  const actualTitle = title ?? 'Mental';
  return (
    <Head>
      <title>{actualTitle}</title>
      <meta name="description" content={actualTitle} />
      <Favicon />
      <Fonts />
    </Head>
  );
};

export default DefaultHead;
