import React from 'react';
import Head from 'next/head';
import * as Sentry from '@sentry/nextjs';

interface Props {
  pixelId: string;
}

const XPixel: React.FC<Props> = ({ pixelId }: Props) => {
  // Script for initializing X Pixel
  // See https://business.x.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html
  // Remove the <script></script> tags from the script that you copy from the "Base Code" on the side
  const XPixelScript = `
!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('config','on7js');
`;

  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: XPixelScript }} />
    </Head>
  );
};

export type XEventContent = Partial<{
  content_id: string;
  content_type: 'product' | 'product_group';
  content_name: string;
}>;

type XEventParameters = Partial<{
  contents?: XEventContent[];
  value?: number;
  currency?: string;
  email_address?: string;
  phone_number?: string;
}>;

// Function to track events with event_id
export const trackXEvent = (eventId: string, parameters?: XEventParameters) => {
  if (typeof window === 'undefined') return;
  // @ts-ignore
  const { twq } = window;
  if (!twq) return;

  try {
    twq('event', eventId, { ...parameters });
    return true;
  } catch (e) {
    console.error('Error tracking X event', e);
    Sentry.captureException(e, {
      contexts: {
        x_pixel: {
          eventId,
          parameters,
        }
      }
    });
    return false;
  }
};

export default XPixel;
