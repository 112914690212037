// components/ErrorBoundary.tsx

import React, { ReactNode, useState, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import DefaultHead from 'components/foundation/head/DefaultHead';
import Header from 'components/foundation/bars/Header';
import pallette from 'styles/pallette';
import grid from 'styles/grid';
import MentalText from 'components/foundation/typography/MentalText';

interface Props {
}

const ErrorScreen: React.FC<Props> = () => {  
  
  return (
    <>
      <DefaultHead title="Mental | Payment" />
      <main>
        <Header />
        <div style={{
          height: '100vh',
          overflowX: 'hidden',
          position: 'relative',
          background: pallette.neutral.w900,
        }}
        >
          <div>
            <div style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
            />
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: pallette.neutral.w900,
            paddingTop: grid.MediumPlus,
            paddingBottom: grid.MediumPlus,
            paddingLeft: grid.Medium,
            paddingRight: grid.Medium,
            marginBottom: grid.XXLarge,
          }}
          >
            <MentalText.Heading2
              color={pallette.neutral.w200}
              isAccentFont
            >
              Sorry dude, something went wrong
            </MentalText.Heading2>
            <MentalText.ParagraphLarge color={pallette.neutral.w200}>
              Don’t worry, we’re on it.
            </MentalText.ParagraphLarge>
          </div>
        </div>
      </main>
    </>
  );
};

export default ErrorScreen;
