import MentalIcon, { MentalIconName, MentalIconType } from 'components/foundation/icons/MentalIcon';
import MentalSpinnerView from 'components/foundation/progress/MentalSpinnerView';
import MentalText from 'components/foundation/typography/MentalText';
import React, { useState } from 'react';
import grid from 'styles/grid';
import pallette from 'styles/pallette';

// Types for button roles and visual modes
const visualModes = ['enabled', 'pressed', 'disabled'];
type VisualMode = typeof visualModes[number];

const buttonRoles = [
  'primary',
  'secondary',
  'pill',
  'pill-square',
  'pill-wide',
  'pill-block',
  'link',
];
type ButtonRole = typeof buttonRoles[number];

type Props = {
  title?: string,
  titleLeft?: string,
  titleRight?: string,
  dark?: boolean,
  role: ButtonRole,
  style?: React.CSSProperties,
  containerStyle?: React.CSSProperties,
  isChosen?: boolean,
  iconName?: MentalIconName,
  iconType?: MentalIconType,
  iconDimension?: number,
  iconColor?: string,
  onPress?: () => void,
  href?: string,
  disabled?: boolean,
  isTitleTextBold?: boolean,
  isTitleAbsolute?: boolean,
  loading?: boolean,
  containerClassName?: string,
  titleFontSize?: string,
  buttonType? : 'button' | 'submit'
};

const MentalButton = ({
  onPress,
  href,
  role,
  style,
  containerStyle,
  disabled,
  title,
  titleLeft,
  titleRight,
  iconName,
  iconType,
  iconDimension,
  iconColor,
  dark,
  isTitleTextBold,
  isTitleAbsolute,
  isChosen,
  loading,
  containerClassName,
  titleFontSize,
  buttonType = "button",
}: Props) => {
  const [isPressed, setIsPressed] = useState(false);

  const visualMode: VisualMode = (() => {
    if (disabled) return 'disabled';
    if (isPressed) return 'pressed';
    return 'enabled';
  })();

  const isSecondaryButton = role === 'secondary';
  const isPillButton = role === 'pill' || role === 'pill-square' || role === 'pill-wide' || role === 'pill-block';

  const getMentalTextCharacteristics = () => {
    // Adjusted for web components
    switch (role) {
      case 'primary':
        return { component: MentalText.Subhead2, isBold: true };
      case 'secondary':
        return { component: MentalText.Subhead2, isBold: false };
      case 'pill':
      case 'pill-square':
      case 'pill-wide':
      case 'pill-block':
        return { component: MentalText.ParagraphLarge, isBold: false };
      default:
        return { component: MentalText.Subhead2, isBold: false };
    }
  };

  const getTextColor = () => {
    const isDisabled = visualMode === 'disabled';

    if (isPillButton) {
      if (isDisabled || isChosen) return pallette.tertiary.w1000;
      return dark ? pallette.neutral.w300 : pallette.tertiary.w1000;
    }

    switch (role) {
      case 'primary':
        return pallette.tertiary.w1000;
      case 'secondary':
        return isDisabled ? pallette.neutral.w500 : pallette.primary.w200;
      case 'link':
        return pallette.accent.link;
      default:
        return pallette.tertiary.w1000;
    }
  };

  const getBackgroundStyle = () => {
    // Background styles for web
    const backgroundColor = () => {
      switch (role) {
        case 'primary':
          if (visualMode === 'pressed') return pallette.primary.w400;
          if (visualMode === 'disabled') return pallette.neutral.w700;
          return pallette.primary.w500;
        case 'secondary':
          if (visualMode === 'pressed') return pallette.tertiary.w1000;
          if (visualMode === 'disabled') return pallette.neutral.w800;
          return pallette.tertiary.w800;
        case 'pill':
        case 'pill-square':
        case 'pill-wide':
        case 'pill-block':
          if (visualMode === 'disabled') return pallette.neutral.w700;
          if (visualMode === 'pressed') return dark ? pallette.neutral.w800 : pallette.primary.w300;
          if (isChosen) return pallette.primary.w400;
          return dark ? pallette.neutral.w900 : pallette.primary.w100;
        case 'link':
          return 'transparent';
        default:
          return '';
      }
    };

    const borderRadius = iconName && !title ? grid.Small : grid.XXSmall;

    return {
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius,
      backgroundColor: backgroundColor(),
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingRight: grid.Large,
      paddingLeft: grid.Large,
      paddingTop: grid.Custom.d12,
      paddingBottom: grid.Custom.d12,
      borderColor: visualMode === 'disabled' ? pallette.neutral.w700 : pallette.neutral.w300,
      borderWidth: isSecondaryButton ? '1px' : undefined,
      borderStyle: isSecondaryButton ? 'solid' : undefined,
    };
  };

  const { component: TextComponent, isBold } = getMentalTextCharacteristics();

  const renderInnerContents = () => {
    if (loading) {
      return (
        <div className='flex justify-center'>
          <MentalSpinnerView dimension={grid.Medium} mode="dark" />
          <TextComponent bold color={getTextColor()}>Please wait ...</TextComponent>
        </div>
      );
    }

    const isTextBold = isBold || isTitleTextBold;
    const titleStyle = {
      ...(isTitleAbsolute ? { position: 'absolute' as 'absolute' } : {}),
      textAlign: 'center' as 'center',
      fontSize: titleFontSize,
    };

    if (titleLeft && titleRight) {
      return (
        <>
          <TextComponent bold={isTextBold} color={getTextColor()} style={titleStyle}>{titleLeft}</TextComponent>
          <TextComponent bold={isTextBold} color={getTextColor()} style={titleStyle}>{titleRight}</TextComponent>
        </>
      );
    }
    if (title) {
      if (iconName) {
        return (
          <>
            <MentalIcon
              iconName={iconName}
              iconType={iconType ?? 'outline'}
              dimension={iconDimension ?? grid.Small}
              color={iconColor}
            />
            <TextComponent bold={isTextBold} color={getTextColor()} style={titleStyle}>{title}</TextComponent>
          </>
        );
      }
      return <TextComponent bold={isTextBold} color={getTextColor()} style={titleStyle}>{title}</TextComponent>;
    }
    if (iconName) {
      return (
        <MentalIcon
          iconName={iconName}
          iconType={iconType ?? 'outline'}
          dimension={iconDimension ?? grid.Small}
          color={iconColor}
        />
      );
    }
    return null;
  };

  const touchesDisabled = disabled || loading;

  return (
    <div className={containerClassName} style={{ alignItems: 'center', width: style?.width ?? grid.XXXXLarge, ...containerStyle }}>
      {
        href ? (
          <a
            href={href}
            // disabled={touchesDisabled}
            onMouseDown={() => !touchesDisabled && setIsPressed(true)}
            onMouseUp={() => setIsPressed(false)}
            onClick={() => {
              if (touchesDisabled) return;
              if (!onPress) return;
              onPress();
            }}
            style={{
              ...getBackgroundStyle(),
              ...style,
              display: 'block',
            }}
          >
            {renderInnerContents()}
          </a>
        ) : (
          <button
            type={buttonType}
            disabled={touchesDisabled}
            onMouseDown={() => !touchesDisabled && setIsPressed(true)}
            onMouseUp={() => setIsPressed(false)}
            onClick={() => {
              if (touchesDisabled) return;
              if (!onPress) return;
              onPress();
            }}
            style={{ ...getBackgroundStyle(), ...style }}
          >
            {renderInnerContents()}
          </button>
        )
      }
    </div>
  );
};

export default MentalButton;
